@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NewslabMedium";
  src: url('../static/fonts/newslab-medium.woff2') format('woff2'), url('../static/fonts/newslab-medium.woff') format('woff');
}

.newslab-medium {
  font-family: NewslabMedium;
}
